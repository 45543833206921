//json database
async function fetchTeamData() {
    try {
      const response = await fetch('src/team.json');
      const data = await response.json();
  
      data.forEach(member => {
        const memberId = member.id;
        const memberWrapper = document.querySelector(`.member-wrapper[data-id="${memberId}"]`);
        if (memberWrapper) {
          memberWrapper.querySelector('.member-name').textContent = member.name;
          memberWrapper.querySelector('.member-position').textContent = member.position;
  
          // Asignar la URL de la imagen del miembro del equipo
          const memberImg = memberWrapper.querySelector('.member-img');
          memberImg.src = member.photo; // Utilizar el campo "photo" para la URL de la imagen
          memberImg.alt = `Foto de ${member.name}`; // Asignar un texto alternativo a la imagen
        }
      });
    } catch (error) {
      console.error('Error al cargar y procesar el JSON:', error);
    }
  }
  
  fetchTeamData();